import React from 'react';
import './Services.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faBullseye, faCloud, faHeadset, faCode, faDatabase } from '@fortawesome/free-solid-svg-icons';

export default function Services() {

    // Icons
    const faDesktopIcon = <FontAwesomeIcon icon={faDesktop} />
    const faBullseyeIcon = <FontAwesomeIcon icon={faBullseye} />
    const faCloudIcon = <FontAwesomeIcon icon={faCloud} />
    const faHeadsetIcon = <FontAwesomeIcon icon={faHeadset} />
    const faCodeIcon = <FontAwesomeIcon icon={faCode} />
    const faDatabaseIcon = <FontAwesomeIcon icon={faDatabase} />

    return (
        <>
            <div id="services">
                <div className="container">
                    <div className="title_headling">
                        <h3>Our Services for Our Valued Customers</h3>
                        <p>We provide a range of solutions designed to meet your unique needs, delivered with quality and care.</p>
                    </div>

                    <div className="service_wrapper">
                        <div className="service_box">
                            <div className="service_icon blue_icon">{faDesktopIcon}</div>
                            <h4 className="number">01</h4>
                            <div className="service_content">
                                <h5>ICT CONSULTANCY SERVICES</h5>
                                <p>We help businesses optimize IT systems to achieve their goals, offering expertise in strategy, cybersecurity, 
                                    cloud, software development, and digital transformation.</p>
                            </div>
                        </div> 
                        <div className="service_box">
                            <div className="service_icon">{faBullseyeIcon}</div>
                            <h4 className="number">02</h4>
                            <div className="service_content">
                                <h5>DIGITAL MARKETING</h5>
                                <p>We are a reliable digital marketing agency that helps companies build strong brands and a powerful online presence to accelerate growth and drive sales.</p>
                            </div>
                        </div>
                        <div className="service_box">
                            <div className="service_icon green_icon">{faCloudIcon}</div>
                            <h4 className="number">03</h4>
                            <div className="service_content">
                                <h5>SAAS DEVELOPMENT</h5>
                                <p>We develop innovative cloud-based solutions that drive growth and efficiency, providing SaaS platforms to automate workflows and streamline operations.</p>
                            </div>
                        </div>
                        <div className="service_box">
                            <div className="service_icon">{faHeadsetIcon}</div>
                            <h4 className="number">04</h4>
                            <div className="service_content">
                                <h5>24/7 SUPPORT</h5>
                                <p>Industry-leading technical support and unrivaled customer service to keep your essential systems operating smoothly, ensuring reliability, efficiency, and continuous business growth.</p>
                            </div>
                        </div>
                        <div className="service_box">
                            <div className="service_icon green_icon">{faCodeIcon}</div>
                            <h4 className="number">05</h4>
                            <div className="service_content">
                                <h5>CLOUD SERVICES</h5>
                                <p>A full range of cutting-edge, innovative, cloud-based IT solutions to transform your business with improved security, greater flexibility, scalability, and enhanced performance.</p>
                            </div>
                        </div>
                        <div className="service_box">
                            <div className="service_icon blue_icon">{faDatabaseIcon}</div>
                            <h4 className="number">06</h4>
                            <div className="service_content">
                                <h5>DATA MANAGEMENT</h5>
                                <p>Ensure data integrity and accessibility with our comprehensive database management services, tailored to your unique business needs and optimized for efficiency.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
