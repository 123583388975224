import React from 'react';
import './Blog.css';
import BlogImage from '../../assets/blog/blog03.jpg';

export default function Blog() {
  return (
    <>
          <section id="blog">
              <div className="container">
                  <div className="blog_wrapper">
                      <div className="blog_col">
                          <h3>EMPOWERING YOUR TECH JOURNEY WITH INNOVATION AND INSIGHT</h3>
                          <p>At our IT blog, we explore the latest trends in technology, provide in-depth tutorials, and share expert insights to help you navigate the digital landscape. Join us as we delve into programming, cybersecurity, cloud computing, and more, empowering you to succeed in the ever-evolving tech world.</p>
                          <div className="btn_wrapper">
                              <a className="btn" href="/">Read More</a>
                          </div>
                      </div>
                      <div className="blog_col">
                          <div className="blog_image">
                              <img src={BlogImage} alt="IT Blog" />
                          </div>
                      </div>
                  </div>

              </div>
          </section>
    </>
  )
}
