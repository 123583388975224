import React from 'react';
import './About.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faCogs, faCloud, faUserShield, faLaptopCode, faPhone } from '@fortawesome/free-solid-svg-icons';
import AboutImage from '../../assets/about/about03.jpg';

export default function About() {
    // Icons
    const faDevelopmentIcon = <FontAwesomeIcon icon={faCode} />; // Coding Icon
    const faIntegrationIcon = <FontAwesomeIcon icon={faCogs} />; // Integration Icon
    const faBrandingIcon = <FontAwesomeIcon icon={faCloud} />; // Cloud Solutions Icon
    const faSupportIcon = <FontAwesomeIcon icon={faUserShield} />; // Support Icon
    const faInfrastructureIcon = <FontAwesomeIcon icon={faLaptopCode} />; // IT Infrastructure Icon
    const faPhoneIcon = <FontAwesomeIcon icon={faPhone} />;

    return (
        <>
            <section id="about">
                <div className="container">
                    <div className="title_headling">
                        <h3>We Provide Innovative IT Solutions!</h3>
                        <p>We are committed to delivering cutting-edge technology and exceptional service to help your business thrive.</p>
                    </div>
                    <div className="about_box_wrapper">
                        <div className="about_box">
                            <div className="about_icon">
                                {faDevelopmentIcon}
                            </div>
                            <div className="about_content">
                                <h5>Software Development</h5>
                                <p>We create custom software solutions tailored to your business needs, enhancing efficiency and productivity.</p>
                            </div>
                        </div>
                        <div className="about_box">
                            <div className="about_icon green_icon">
                                {faIntegrationIcon}
                            </div>
                            <div className="about_content">
                                <h5>System Integration</h5>
                                <p>Our team specializes in integrating various systems to streamline processes and improve data flow.</p>
                            </div>
                        </div>
                        <div className="about_box">
                            <div className="about_icon blue_icon">
                                {faBrandingIcon}
                            </div>
                            <div className="about_content">
                                <h5>Cloud Solutions</h5>
                                <p>Leverage the power of the cloud for scalability, flexibility, and cost-efficiency in your operations.</p>
                            </div>
                        </div>
                    </div>

                    <div className="about_box_details">
                        <div className="about_col">
                            <div className="about_image">
                                <img src={AboutImage} alt="about" className="about_main"/>
                            </div>
                            <div className="img_info__box">
                                <h6 className="img_info__title">GET A FREE IT CONSULTATION TODAY!</h6>
                                <p>Contact us to explore how we can elevate your IT infrastructure and drive innovation in your business.</p>
                               
                            </div>
                        </div>
                        <div className="about_col more_space">
                            <h3>We have IT expertise that will boost your business growth</h3>
                            <p>Our team of IT professionals is dedicated to providing tailored solutions that meet the unique challenges of your business.</p>

                            <div className="grid_info">
                                <div className="icon">{faSupportIcon}</div>
                                <div className="detail">
                                    <h4>24/7 Support</h4>
                                    <p>Our dedicated support team is available around the clock to assist you with any technical challenges.</p>
                                </div>
                            </div>
                            <div className="grid_info">
                                <div className="icon green_icon">{faInfrastructureIcon}</div>
                                <div className="detail">
                                    <h4>Robust IT Infrastructure</h4>
                                    <p>We help you set up and maintain a secure and reliable IT infrastructure that supports your operations.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

